<template>
  <div class="d-flex justify-content-center mt-5">
    <div
      class="card"
      style="width: 1200px"
    >
      <div class="card-header d-flex justify-content-between">
        <h5 class="mt-1 mb-0">
          Forecast users
        </h5>
      </div>
      <div
        class="card-body p-0"
        style="height: 52em"
      >
        <ag-grid-vue
          :columnDefs="columnForecastUsersDefs"
          :defaultColDef="defaultForecastUsersColDef"
          :rowData="forecastUsers"
          :pagination="true"
          :paginationAutoPageSize="true"
          class="ag-theme-alpine w-100 h-100 slaEditModal"
        />
      </div>
    </div>
  </div>
  <ForecastUserEditModal
    ref="forecastUserModal"
    :viewType="modalViewType"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { modalViewTypes } from '@/components/constants';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';
import ForecastUserEditModal from '@/components/Forecast/ForecastUserEditModal.vue';

export default {
  components: {
    ForecastUserEditModal,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      modalViewType: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      actualBrands: 'forecast/companyItems',
      forecastUsers: 'forecast/forecastUsers'
    }),
    defaultForecastUsersColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnForecastUsersDefs() {
      return [
        {
          headerName: '',
          field: 'userId',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.rowRecipientClickHandler(field);
            }
          },
          width: 80
        },
        {
          headerName: 'User name',
          field: 'userName',
          sortable: true,
          width: 250
        },
        {
          headerName: 'Email',
          field: 'email',
          sortable: true,
          width: 340
        },
        {
          headerName: 'Receive Lack of Forecast alerts',
          field: 'receiveLackOfForecastAlert',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveNewForecastAlert',
          headerName: 'Receive New Forecast alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveForecastDecision',
          headerName: 'Receive Forecast decisions',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveMismatchAlert',
          headerName: 'Receive Forecast Mismatch alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
      ];
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchActualBrands();
    await this.fetchForecastUsers();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchActualBrands: 'forecast/fetchCompanyItems',
      fetchForecastUsers: 'forecast/fetchForecastUsers'
    }),
    rowRecipientClickHandler(id) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.forecastUserModal.initModal(id);
    }
  }
};
</script>

<style scoped>

</style>
