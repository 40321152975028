<template>
  <div
    id="forecast-view-modal"
    ref="forecast-view-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ titleModal }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="view-modal-body">
          <div class="grid-wrapper">
            <div class="content-wrapper">
              <div class="totals-row">
                <div
                  v-for="(total, key, index) in columnTotals"
                  :key="key"
                  class="totals-cell"
                  :style="getColumnStyle(index)"
                >
                  <div class="totals-cell-content">
                    <span v-if="key === 'week'">{{ 'Total' }}</span>
                    <span v-else-if="notDisplayTotal(key)">{{ '' }}</span>
                    <span v-else>{{ total !== undefined ? total : 'N/A' }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="initialized"
                class="card-body modalbody p-0 w-100 d-flex justify-content-center"
              >
                <ag-grid-vue
                  ref="agGrid"
                  :columnDefs="columnDefs"
                  :defaultColDef="defaultColDef"
                  :pagination="true"
                  :paginationPageSize="30"
                  :rowData="forecastData"
                  :getRowStyle="getRowStyle"
                  class="ag-theme-alpine h-100 w-100"
                  sideBar="filters"
                  @grid-ready="onGridReady"
                />
              </div>
              <Spinner v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';
import Spinner from '@/components/common/Spinner';

const TOTALS_EXCEPTION_LIST = [ 'date', 'ecomDescription', 'whStartDate', 'whCancelDate' ];

export default {
  components: {
    AgGridVue,
    Spinner
  },
  data() {
    return {
      titleModal: null,
      forecastModal: null,
      initialized: false,
      forecastData: [],
      columnTotals: null,
      gridApi: null,
      columnApi: null,
      columnWidths: {},
      resizeListener: null,
    };
  },
  computed: {
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      };
    },
    notDisplayTotal() {
      return (key) => TOTALS_EXCEPTION_LIST.includes(key);
    },
    columnDefs() {
      return [
        {
          headerName: 'WEEK',
          field: 'week',
          width: 80,
          sortable: true
        },
        {
          headerName: 'DATE',
          field: 'date',
          width: 110,
          sortable: true
        },
        {
          headerName: 'ECOM_ORDERS',
          field: 'ecomOrders',
          width: 150,
          sortable: true
        },
        {
          headerName: 'ECOM_UNITS',
          field: 'ecomUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'ECOM_DESCRIPTION',
          field: 'ecomDescription',
          width: 150,
          sortable: true
        },
        {
          headerName: 'LTM_LASER_UNITS',
          field: 'laserUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'LTM_EMB_UNITS',
          field: 'embUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RETURNS_UNITS',
          field: 'returnsUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_UNITS',
          field: 'whUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_PO_NUMBER',
          field: 'whPoNumber',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_START_DATE',
          field: 'whStartDate',
          width: 150,
          sortable: true
        },
        {
          headerName: 'WH_CANCEL_DATE',
          field: 'whCancelDate',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_PO_NUMBER',
          field: 'receivingPoNumber',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_CONTAINERS',
          field: 'receivingContainers',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_PAL_UNITS',
          field: 'receivingPalUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_CART_UNITS',
          field: 'receivingCartUnits',
          width: 150,
          sortable: true
        },
        {
          headerName: 'RECEIVING_UN_UNITS',
          field: 'receivingUnUnits',
          width: 150,
          sortable: true
        }
      ];
    }
  },
  async mounted() {
    this.forecastModal = new Modal(this.$refs['forecast-view-modal']);
    window.addEventListener('resize', this.updateColumnWidths);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateColumnWidths);
    if (this.gridApi && this.resizeListener) {
      this.gridApi.removeEventListener('columnResized', this.resizeListener);
    }
  },
  methods: {
    ...mapActions({
      fetchForecastById: 'forecast/fetchForecastById',
    }),
    async initModal(forecastId) {
      this.initialized = false;
      this.titleModal = forecastId;
      this.forecastModal.show();
      this.forecastData = await this.fetchForecastById(forecastId);
      this.calculateColumnTotals();
      this.initialized = true;
      this.$nextTick(() => {
        this.updateColumnWidths();
      });
    },
    calculateColumnTotals() {
      const totals = {};
      const formatter = new Intl.NumberFormat('en-US');
      this.columnDefs.forEach((col) => {
        if (typeof col.field === 'string') {
          const total = this.forecastData.reduce((sum, row) => {
            const value = row[col.field];
            return sum + (typeof value === 'number' ? value : 0);
          }, 0);
          totals[col.field] = formatter.format(total);
        }
      });
      this.columnTotals = totals;
    },
    getColumnStyle(index) {
      const field = this.columnDefs[index].field;
      const width = this.columnWidths[field] || this.columnDefs[index].width;
      return {
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
        width: `${width}px`,
        border: '1px solid #ddd',
        boxSizing: 'border-box',
      };
    },
    getRowStyle(params) {
      if (params.data.applied === null) {
        return { background: '#ececef' };
      }
      return (params.data.applied) === false ? { background: '#fbe9eb' } : { background: '#ecfdf0' };
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      if (this.resizeListener) {
        this.gridApi.removeEventListener('columnResized', this.resizeListener);
      }

      this.resizeListener = () => {
        this.updateColumnWidths();
      };
      this.gridApi.addEventListener('columnResized', this.resizeListener);

      this.updateColumnWidths();
    },
    updateColumnWidths() {
      setTimeout(() => {
        const gridElement = this.$refs.agGrid.$el;
        const headerCells = gridElement.querySelectorAll('.ag-header-cell');

        headerCells.forEach((cell, index) => {
          const field = this.columnDefs[index].field;
          this.columnWidths[field] = cell.offsetWidth;
        });

        this.$forceUpdate();
      }, 100);
    },
  }
};
</script>
<style>
@import "style.css";
</style>
