<template>
  <div
    ref="user-management-edit-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form
            v-if="initialized"
          >
            <TextInput
              v-model="user.fullName"
              description="User name"
              label="User name"
              :disableAutocomplete="true"
              :readonly="isUserDisabled"
            />
            <TextInput
              v-model="user.email"
              :errors="v$.user.email.$errors"
              description="Email"
              label="Email"
              :readonly="!isEmailFieldEditable"
              :disableAutocomplete="true"
              @blur="v$.user.email.$touch"
            />
            <TextInput
              v-model="user.slackId"
              description="Slack ID"
              label="Slack"
              :readonly="isUserDisabled"
              :disableAutocomplete="true"
            />
            <SelectorWithTooltips
              v-model="user.role"
              :errors="v$.user.role.$errors"
              :options="rolesOptions"
              :disabled="isUserDisabled"
              description="Choose user role"
              label="User role"
            />
            <CheckBoxSelectorWithInputs
              v-model="user.additionalPrivileges"
              :errors="v$.user.additionalPrivileges.$errors"
              :options="additionalUserPrivilegeOptions"
              :disabled="isUserDisabled"
              description="Choose user additional privileges"
              label="User additional privileges"
            />
            <CheckBoxSelectorWithSelectAllHook
              v-model="user.brands"
              v-model:selectAll="user.selectAllBrands"
              :options="brandsOptions"
              description="Processed brands"
              label="Processed brands"
            />
            <SelectInput
              v-model="updatedLocations"
              :options="locationsOptions"
              :multiple="true"
              :showAddAll="true"
              :disabled="isUserDisabled"
              placeholder="Pick locations"
              label="Processed locations"
              description="Processed locations"
              style="height: 150px;"
            />
            <CheckboxInput
              v-model="user.isUsingMFA"
              description="You can disable MFA configuration for the user."
              label="Is MFA configured"
              :disabled="!user.isUsingMFA || isUserDisabled"
            />
            <TextInput
              v-if="isPasswordSectionVisible"
              v-model="user.password"
              :errors="v$.user.password.$errors"
              description="Password"
              label="Password"
              inputType="password"
              :disableAutocomplete="true"
              @blur="v$.user.password.$touch"
            />
            <TextInput
              v-if="isPasswordSectionVisible"
              v-model="user.passwordConfirmation"
              :errors="v$.user.passwordConfirmation.$errors"
              description="Password confirmation"
              label="Password confirmation"
              inputType="password"
              :disableAutocomplete="true"
              @blur="v$.user.passwordConfirmation.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <div>
              <button
                :disabled="isDeleteButtonDisabled"
                class="btn btn-danger"
                @click="showDeleteConfirmationModalHandler"
              >
                Deactivate
              </button>
              &nbsp;
              <button
                :disabled="isDeleteButtonDisabled"
                class="btn btn-warning"
                @click="showResetPasswordModalHandler"
              >
                Reset password
              </button>
            </div>
            <button
              v-if="isUserDisabled"
              class="btn btn-primary"
              @click="showActivateUserModalHandler"
            >
              Activate
            </button>
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="saveChangesHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeleteConfirmationModal
    ref="user-management-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteUserHandler"
  >
    <template #alert-message>
      <h6>
        <i class="bi bi-exclamation-triangle" />
        Do you really want to deactivate this user?
      </h6>
    </template>
    <template #confirm-button>
      I agree and deactivate
    </template>
  </DeleteConfirmationModal>
  <ConfirmationModal
    ref="userManagementActivateConfirmationModal"
    :onCancelHandler="cancelActivateUserHandler"
    :onConfirmHandler="activateUserHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The user will be activated. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <ResetPasswordModal
    ref="resetPasswordModal"
    :userId="user.id"
  />
</template>
<script>
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import TextInput from '@/components/common/TextInput';
import CheckboxInput from '@/components/common/CheckboxInput';
import Spinner from '@/components/common/Spinner';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, email, minLength } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import { mappedUserPrivileges, mappedUserRoles, modalViewTypes, userStatuses } from '@/components/constants';
import SelectInput from '@/components/common/SelectInput';
import { passwordStrengthValidator } from '@/lib/validators';
import ResetPasswordModal from '@/components/UserManagement/ResetPasswordModal.vue';
import ConfirmationModal from '../common/ConfirmationModal';
import _ from 'lodash';
import { assignLocations } from '@/components/LaborPlanning/constants';
import SelectorWithTooltips from '@/components/common/SelectorWithTooltips.vue';
import CheckBoxSelectorWithInputs from '@/components/common/CheckBoxSelectorWithInputs.vue';
import CheckBoxSelectorWithSelectAllHook from '@/components/common/CheckBoxSelectorWithSelectAllHook.vue';

const defaultUser = {
  email: null,
  password: null,
  passwordConfirmation: null,
  slackId: null,
  locations: null,
  role: null,
  additionalPrivileges: [],
  brands: [],
  selectAllBrands: false,
};

export default {
  components: {
    CheckBoxSelectorWithSelectAllHook,
    CheckBoxSelectorWithInputs,
    SelectorWithTooltips,
    ResetPasswordModal,
    ConfirmationModal,
    SelectInput,
    CheckboxInput,
    DeleteConfirmationModal,
    TextInput,
    Spinner
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      editModal: null,
      confirmationModalDelete: null,
      confirmationUserActivationModal: null,
      initialized: false,
      user: {
        email: null,
        fullName: null,
        password: null,
        passwordConfirmation: null,
        isUsingMFA: null,
        status: null,
        slackId: null,
        locations: null,
        role: null,
        additionalPrivileges: null,
        brands: null,
        selectAllBrands: null
      },
      updatedLocations: null
    };
  },
  validations() {
    return {
      user: {
        email: {
          required,
          email
        },
        password: {
          required: requiredIf(() => this.viewType === modalViewTypes.add),
          minLength: minLength(8),
          passwordStrengthValidator,
          shouldMatchWithConfirmation: {
            $validator: (value) => {
              return value === this.user.passwordConfirmation;
            },
            $message: 'The values for "password" and "password confirmation" should be the same'
          }
        },
        passwordConfirmation: {
          required: requiredIf(() => this.viewType === modalViewTypes.add),
          minLength: minLength(8),
          passwordStrengthValidator,
          shouldMatchWithConfirmation: {
            $validator: (value) => {
              return value === this.user.password;
            },
            $message: 'The values for "password" and "password confirmation" should be the same'
          }
        },
        role: { required },
        additionalPrivileges: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'userRole/userRoles',
      userPrivileges: 'userRole/userPrivileges',
      brands: 'brandManagement/processedBrandNames',
    }),
    brandsOptions() {
      return Object.values(this.brands).map((item) => ({
        key: item.id,
        value: item.lookupName,
      }));
    },
    rolesOptions() {
      return this.userRoles.map((role) => ({
        key: role.id,
        value: mappedUserRoles[role.name]?.role || role.name,
        description: mappedUserRoles[role.name]?.description
      }));
    },
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit user' : 'Add New';
    },
    isSaveButtonDisabled() {
      return !this.initialized || this.isUserDisabled;
    },
    isDeleteButtonDisabled() {
      return !this.initialized ||
        this.viewType === modalViewTypes.add ||
        this.isUserDisabled;
    },
    isPasswordSectionVisible() {
      return this.viewType === modalViewTypes.add;
    },
    isEmailFieldEditable() {
      return this.viewType === modalViewTypes.add;
    },
    isUserDisabled() {
      return this.user.status === userStatuses.disabled;
    },
    locationsOptions() {
      return _.map(assignLocations , (value, key) => ({ key, value: key }));
    },
    findViewerRole() {
      return this.userRoles.find((role) => role.name === 'ROLE_VIEWER')?.id;
    },
    additionalUserPrivilegeOptions() {
      const { privileges: rolePrivileges = []} = this.userRoles.find(({ id }) => id === this.user.role) || {};

      return this.userPrivileges
        .filter(({ id }) => !rolePrivileges.some((privilege) => privilege.id === id))
        .map(({ id, name }) => ({
          key: id,
          value: mappedUserPrivileges[name] || name,
        }));
    }
  },
  async mounted() {
    await this.fetchRoles();
    await this.fetchUserPrivileges();
    this.editModal = new Modal(this.$refs['user-management-edit-modal']);
    this.confirmationModalDelete = this.$refs['user-management-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete.hide();
    this.confirmationUserActivationModal = this.$refs['userManagementActivateConfirmationModal'].confirmationModal;
    this.confirmationUserActivationModal.hide();
  },
  methods: {
    ...mapActions({
      fetchUsers: 'userManagement/fetchUsers',
      fetchUserById: 'userManagement/fetchUserById',
      createUser: 'userManagement/createUser',
      updateUser: 'userManagement/updateUser',
      deleteUser: 'userManagement/deleteUser',
      activateUser: 'userManagement/activateUser',
      updateUserLocationGroups: 'dashboard/updateTableauUserGroups',
      fetchRoles: 'userRole/fetchUserRoles',
      fetchUserPrivileges: 'userRole/fetchUserPrivileges'
    }),
    async initModal(itemId) {
      this.initialized = false;
      this.editModal.show();
      const itemToEdit = itemId ? await this.fetchUserById(itemId) : { ...defaultUser };
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.user.id = newValue.id;
      this.user.fullName = newValue.fullName;
      this.user.email = newValue.email;
      this.user.isUsingMFA = newValue.usingMFA;
      this.user.status = newValue.status;
      this.user.password = null;
      this.user.passwordConfirmation = null;
      this.user.slackId = newValue.slackId;
      this.user.locations = newValue.locations;
      this.updatedLocations = newValue.locations;
      this.user.role = newValue?.role || this.findViewerRole;
      this.user.additionalPrivileges = newValue?.additionalPrivileges;
      this.user.brands = newValue.brands;
      this.user.selectAllBrands = newValue.selectAllBrands;
    },
    showDeleteConfirmationModalHandler() {
      this.editModal.hide();
      this.confirmationModalDelete.show();
    },
    async showResetPasswordModalHandler() {
      this.editModal.hide();
      await this.$refs.resetPasswordModal.initModal();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteUserHandler() {
      try {
        if (this.user.locations.length > 0) {
          await this.deleteUserGroups();
        }
        await this.deleteUser(this.user.id);
        await this.fetchUsers();
      } catch (e) {
        this.$toast.error('Failed to deactivate user.<br>' + e.message);
      }
    },
    async saveChangesHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      try {
        if (this.updatedLocations !== this.user.locations) {
          this.user.locations = this.updatedLocations;
          await this.updateUserGroups();
        }
        if (this.viewType === modalViewTypes.edit) {
          await this.updateUser({
            id: this.user.id,
            fullName: this.user.fullName,
            email: this.user.email,
            isUsingMFA: this.user.isUsingMFA,
            slackId: this.user.slackId,
            locations: this.user.locations,
            role: this.user.role,
            additionalPrivileges: this.user.additionalPrivileges,
            brands: this.user.brands,
            selectAllBrands: this.user.selectAllBrands
          });
          this.$toast.success('User successfully updated');
        } else {
          await this.createUser({
            ...this.user
          });
          this.$toast.success('User successfully created');
        }
        await this.fetchUsers();
        this.editModal.hide();
        this.updateItemData({ ...defaultUser });
      } catch (e) {
        this.$toast.error('Failed to save the data.<br>' + e.message);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
    showActivateUserModalHandler() {
      this.editModal.hide();
      this.confirmationUserActivationModal.show();
    },
    async activateUserHandler() {
      const id = this.user.id;
      await this.activateUser(id);
      await this.fetchUsers();
      this.confirmationUserActivationModal.hide();
    },
    cancelActivateUserHandler() {
      this.confirmationUserActivationModal.hide();
      this.editModal.show();
    },
    async updateUserGroups() {
      const data = {
        username: this.user.email,
        groups: _.map(this.user.locations, (v) => assignLocations[v])
      };
      await this.updateUserLocationGroups(data);
    },
    async deleteUserGroups() {
      const data = {
        username: this.user.email,
        groups: []
      };
      await this.updateUserLocationGroups(data);
    }
  }
};
</script>
