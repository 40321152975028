import _ from 'lodash';
import { menuSections, userPrivileges, userRoles } from '@/components/constants';

export const routes = [
  {
    path: '/',
    redirect: '/auth',
    meta: {
      allowedUserRoles: _.values(userRoles)
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/components/Auth.vue'),
    meta: {
      allowedUserRoles: _.values(userRoles)
    }
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/components/Jobs/Jobs'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.manage
    }
  },
  {
    path: '/logs/:id',
    name: 'logs',
    component: () => import('@/components/Jobs/JobLogs'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ]
    }
  },
  {
    path: '/ukg-mapping',
    name: 'ukg-mapping',
    component: () => import('@/components/UKGMapping/UKGMapping'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.configuration
    }
  },
  {
    path: '/company-type-mapping',
    name: 'company-type-mapping',
    component: () => import('@/components/CompanyTypeMapping/CompanyTypeMapping'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.configuration
    }
  },
  {
    path: '/error/:code',
    name: 'error',
    component: () => import('@/components/Error.vue'),
    meta: {
      allowedUserRoles: _.values(userRoles)
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'error404',
    redirect: '/error/404',
    meta: {
      allowedUserRoles: _.values(userRoles)
    }
  },
  {
    path: '/sla-configuration',
    name: 'sla-configuration',
    component: () => import('@/components/SlaConfiguration/SlaConfiguration'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.configuration
    }
  },
  {
    path: '/agency',
    name: 'agency',
    component: () => import('@/components/Agency/Agency'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.configuration
    }
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: () => import('@/components/UserManagement/UserManagement'),
    meta: {
      allowedUserRoles: [userRoles.superAdmin],
      menuSection: menuSections.maintenance,
      allowedUserPrivileges: [
        userPrivileges.getUser,
        userPrivileges.getPrivileges,
      ],
    },
  },
  {
    path: '/customer-portal-user-management',
    name: 'customer-portal-user-management',
    component: () => import('@/components/CustomerPortalUsersManagement/CustomerPortalUsersManagement'),
    meta: {
      allowedUserRoles: [
        userRoles.superAdmin,
        userRoles.admin,
      ],
      allowedUserPrivileges: [
        userPrivileges.getBrandsActualNames,
        userPrivileges.getCustomerPortalUser
      ],
      menuSection: menuSections.maintenance
    }
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/components/UserProfile/UserProfile'),
    meta: {
      allowedUserRoles: _.values(userRoles)
    }
  },
  {
    path: '/admin-actions',
    name: 'admin-actions',
    component: () => import('@/components/AdminActions/AdminActions'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.manage
    }
  },
  {
    path: '/invoices-upload',
    name: 'invoices-upload',
    component: () => import('@/components/InvoicesUpload'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin, userRoles.accountTeam ],
      menuSection: menuSections.clients,
      allowedUserPrivileges: [
        userPrivileges.uploadInvoices,
        userPrivileges.getLastUploadedInvoices,
      ]
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/components/Dashboard/Dashboard'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.clients,
    }
  },
  {
    path: '/forecasts',
    name: 'forecasts-main',
    component: () => import('@/components/Forecast/ForecastMain'),
    meta: {
      allowedUserRoles: [
        userRoles.superAdmin, userRoles.admin, userRoles.viewer,
        userRoles.opsTeam,userRoles.accountTeam
      ],
      menuSection: menuSections.clients,
      allowedUserPrivileges: [
        userPrivileges.getLastUploadForecasts,
        userPrivileges.getForecastActualBrands,
      ]
    }
  },
  {
    path: '/forecasts/:brandId',
    name: 'forecasts',
    component: () => import('@/components/Forecast/Forecast'),
    meta: {
      allowedUserRoles: [
        userRoles.superAdmin, userRoles.admin, userRoles.accountTeam,
        userRoles.opsTeam, userRoles.viewer
      ],
      allowedUserPrivileges: [
        userPrivileges.getForecastActualBrands,
        userPrivileges.getForecastByBrand,
        userPrivileges.getForecastRecipient,
        userPrivileges.getForecastRule,
      ]
    }
  },
  {
    path: '/forecasts/configuration',
    name: 'forecast-configuration',
    component: () => import('@/components/Forecast/ForecastConfig'),
    meta: {
      allowedUserRoles: [ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.configuration,
    }
  },
  {
    path: '/brand-management',
    name: 'brand-management',
    component: () => import('@/components/BrandManagement/BrandManagement'),
    meta: {
      allowedUserRoles:[ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.clients,
      allowedUserPrivileges: [
        userPrivileges.getBrandsOverviews,
        userPrivileges.getBrandExclusions,
      ]
    }
  },
  {
    path: '/brand-management/:brandId',
    name: 'brand-details',
    component: () => import('@/components/BrandManagement/BrandDetails'),
    meta: {
      allowedUserRoles:[ userRoles.superAdmin, userRoles.admin ],
      allowedUserPrivileges: [
        userPrivileges.getBrandDetails,
        userPrivileges.getCompanyTypeMapping,
        userPrivileges.getDashboard,
        userPrivileges.getForecastRule,
        userPrivileges.getForecastRecipient,
        userPrivileges.getSlaRule,
        userPrivileges.getBrandUph,
        userPrivileges.getCustomerPortalUser,
        userPrivileges.getUkgMapping,
        userPrivileges.getTableauDashboardTemplates
      ]
    }
  },
  {
    path: '/labor-planning',
    name: 'labor-planning',
    component: () => import('@/components/LaborPlanning/LaborPlanning'),
    meta: {
      allowedUserRoles: [
        userRoles.superAdmin,
        userRoles.admin,
        userRoles.planner,
        userRoles.manager
      ],
      menuSection: menuSections.clients,
      allowedUserPrivileges: [
        userPrivileges.getLpPlan
      ]
    }
  },
  {
    path: '/labor-planning/configuration',
    name: 'labor-planning-configuration',
    component: () => import('@/components/LaborPlanning/LaborPlanningConfiguration'),
    meta: {
      allowedUserRoles:[ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.configuration,
      allowedUserPrivileges: [
        userPrivileges.getLpRule
      ]
    }
  },
  {
    path: '/labor-planning/view/:planId',
    name: 'labor-planning-view',
    component: () => import('@/components/LaborPlanning/LaborPlanningView'),
    meta: {
      allowedUserRoles:[ userRoles.superAdmin, userRoles.admin, userRoles.planner, userRoles.manager ],
      menuSection: menuSections.clients,
      allowedUserPrivileges: [
        userPrivileges.getLpPlan
      ]
    }
  },
  {
    path: '/auditlog',
    name: 'auditlog',
    component: () => import('@/components/AuditLog/AuditLog'),
    meta: {
      allowedUserRoles:[ userRoles.superAdmin, userRoles.admin ]
    }
  },
  {
    path: '/location-timezone-mapping',
    name: 'location-timezone-mapping',
    component: () => import('@/components/LocationTimeZoneMapping/LocationTimeZoneMapping.vue'),
    meta: {
      allowedUserRoles:[ userRoles.superAdmin, userRoles.admin ],
      menuSection: menuSections.configuration,
    }
  }
];
