<template>
  <div
    class="main-menu"
    :class="{'view-mode-short': !isTextVisible}"
  >
    <nav class="navbar navbar-dark bg-dark h-100 align-content-start mb-3 navbar-scrollable d-flex align-content-between">
      <div class="container-fluid">
        <div class="d-flex justify-content-between align-content-between w-100">
          <router-link
            class="navbar-brand"
            :to="logoLink"
          >
            {{ isTextVisible ? 'Outerspace' : 'O' }}
          </router-link>
          <TooltippedIcon
            class="navbar-brand view-mode-switcher"
            iconClass="bi-three-dots-vertical"
            tooltipText="Minimize"
            :class="{'d-none': !isTextVisible}"
            @click="switchViewMode"
          />
          <TooltippedIcon
            class="navbar-brand view-mode-switcher view-mode-short-switcher"
            iconClass="bi-list"
            tooltipText="Expand"
            :class="{'d-none': isTextVisible}"
            @click="switchViewMode"
          />
        </div>
        <MenuSection
          v-if="isSectionAccessable(menuSections.manage)"
          iconClass="bi-joystick"
          itemName="Manage"
          :showText="isTextVisible"
          tooltipText="Manage"
          :isExpanded="isManageSectionExpanded"
        >
          <template #section-content>
            <MenuItem
              v-if="isAccessable('jobs')"
              :isActive="isActive('jobs')"
              :showText="isTextVisible"
              route="/jobs"
              iconClass="bi-calendar2-range-fill"
              itemName="Jobs"
              tooltipText="Jobs"
            />
            <MenuItem
              v-if="isAccessable('admin-actions')"
              :isActive="isActive('admin-actions')"
              :showText="isTextVisible"
              route="/admin-actions"
              iconClass="bi-toggles"
              itemName="Admin actions"
              tooltipText="Admin actions"
            />
          </template>
        </MenuSection>
        <MenuSection
          v-if="isSectionAccessable(menuSections.clients)"
          iconClass="bi-building"
          itemName="Clients"
          :showText="isTextVisible"
          tooltipText="Clients"
          :isExpanded="isClientsSectionExpanded"
        >
          <template #section-content>
            <MenuItem
              v-if="isAccessable('brand-management')"
              :isActive="isActive('brand-management')"
              :showText="isTextVisible"
              route="/brand-management"
              iconClass="bi-building"
              itemName="Brand Management"
              tooltipText="Brand Management"
            />
            <MenuItem
              v-if="isAccessable('dashboard')"
              :isActive="isActive('dashboard')"
              :showText="isTextVisible"
              route="/dashboard"
              iconClass="bi-layout-text-window"
              itemName="New Dashboard"
              tooltipText="New Dashboard"
            />
            <MenuItem
              v-if="isAccessable('forecasts')"
              :isActive="isActive('forecasts') || isActive('forecasts-main')"
              :showText="isTextVisible"
              route="/forecasts"
              iconClass="bi-bar-chart-fill"
              itemName="Forecasts"
              tooltipText="Forecasts"
            />
            <MenuItem
              v-if="isAccessable('labor-planning') || isAccessable('labor-planning-view')"
              :isActive="isActive('labor-planning') || isActive('labor-planning-view')"
              :showText="isTextVisible"
              route="/labor-planning"
              iconClass="bi-calendar-week"
              itemName="Labor Planning"
              tooltipText="Labor Planning"
            />
            <MenuItem
              v-if="isAccessable('invoices-upload')"
              :isActive="isActive('invoices-upload')"
              :showText="isTextVisible"
              route="/invoices-upload"
              iconClass="bi-cloud-upload-fill"
              itemName="Invoices upload"
              tooltipText="Invoices upload"
            />
          </template>
        </MenuSection>
        <MenuSection
          v-if="isSectionAccessable(menuSections.configuration)"
          iconClass="bi-gear-fill"
          itemName="Configuration"
          :showText="isTextVisible"
          tooltipText="Configuration"
          :isExpanded="isConfigurationSectionExpanded"
        >
          <template #section-content>
            <MenuItem
              v-if="isAccessable('ukg-mapping')"
              :isActive="isActive('ukg-mapping')"
              :showText="isTextVisible"
              route="/ukg-mapping"
              iconClass="bi-list-check"
              itemName="UKG mapping"
              tooltipText="UKG mapping"
            />
            <MenuItem
              v-if="isAccessable('company-type-mapping')"
              :isActive="isActive('company-type-mapping')"
              :showText="isTextVisible"
              route="/company-type-mapping"
              iconClass="bi-list-check"
              itemName="Company Type mapping"
              tooltipText="Company Type mapping"
            />
            <MenuItem
              v-if="isAccessable('sla-configuration')"
              :isActive="isActive('sla-configuration')"
              :showText="isTextVisible"
              route="/sla-configuration"
              iconClass="bi-tools"
              itemName="SLA Configuration"
              tooltipText="SLA Configuration"
            />
            <MenuItem
              v-if="isAccessable('agency')"
              :isActive="isActive('agency')"
              :showText="isTextVisible"
              route="/agency"
              iconClass="bi-tools"
              itemName="Agency Configuration"
              tooltipText="Agency Configuration"
            />
            <MenuItem
              v-if="isAccessable('labor-planning-configuration')"
              :isActive="isActive('labor-planning-configuration')"
              :showText="isTextVisible"
              route="/labor-planning/configuration"
              iconClass="bi-calendar-week"
              itemName="Labor Planning Configuration"
              tooltipText="Labor Planning Configuration"
            />
            <MenuItem
              v-if="isAccessable('location-timezone-mapping')"
              :isActive="isActive('location-timezone-mapping')"
              :showText="isTextVisible"
              route="/location-timezone-mapping"
              iconClass="bi-tools"
              itemName="Location TimeZone Mapping"
              tooltipText="Location TimeZone Mapping"
            />
            <MenuItem
              v-if="isAccessable('forecast-configuration')"
              :isActive="isActive('forecast-configuration')"
              :showText="isTextVisible"
              route="/forecasts/configuration"
              iconClass="bi-tools"
              itemName="Forecast Configuration"
              tooltipText="Forecast Configuration"
            />
          </template>
        </MenuSection>
        <MenuSection
          v-if="isSectionAccessable(menuSections.maintenance)"
          iconClass="bi-gem"
          itemName="Maintenance"
          :showText="isTextVisible"
          tooltipText="Maintenance"
          :isExpanded="isMaintenanceSectionExpanded"
        >
          <template #section-content>
            <MenuItem
              v-if="isAccessable('user-management')"
              :isActive="isActive('user-management')"
              :showText="isTextVisible"
              route="/user-management"
              iconClass="bi-people-fill"
              itemName="User Management"
              tooltipText="User Management"
            />
            <MenuItem
              v-if="isAccessable('customer-portal-user-management')"
              :isActive="isActive('customer-portal-user-management')"
              :showText="isTextVisible"
              route="/customer-portal-user-management"
              iconClass="bi-people"
              itemName="Customer Portal User Management"
              tooltipText="Customer Portal User Management"
            />
          </template>
        </MenuSection>
        <ul class="navbar-nav me-auto mb-0">
          <MenuItem
            v-if="isAccessable('user-profile')"
            :isActive="isActive('user-profile')"
            :showText="isTextVisible"
            route="/user-profile"
            iconClass="bi-person-fill"
            itemName="Profile"
            tooltipText="Profile"
          />
          <MenuItem
            v-if="isAccessable('auditlog')"
            :isActive="isActive('auditlog')"
            :showText="isTextVisible"
            route="/auditlog"
            iconClass="bi-clock-history"
            itemName="Audit log"
            tooltipText="Audit log"
          />
          <MenuItem
            :isActive="false"
            :showText="isTextVisible"
            route="/user-profile"
            iconClass="bi-box-arrow-right"
            itemName="Log Out"
            tooltipText="Log Out"
            @click.prevent="logOut"
          />
        </ul>
      </div>
      <div class="container-fluid">
        <ul class="navbar-nav me-auto mb-0">
          <MenuItem
            v-if="isAccessable('user-profile')"
            :href="supportPageUrl"
            :isActive="false"
            :showText="isTextVisible"
            iconClass="bi-vector-pen"
            itemName="Get Support"
            tooltipText="Get Support"
            target="_blank"
          />
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { validateRouteAccess, validateSectionAccess } from '@/router/routeAccessValidator';
import { mapActions, mapGetters } from 'vuex';
import config from '@/config';
import MenuItem from '@/components/common/MenuItem';
import MenuSection from '@/components/common/MenuSection';
import TooltippedIcon from '@/components/common/TooltippedIcon';
import { menuSections } from '@/components/constants';

const viewModes = {
  full: 'FULL',
  short: 'SHORT'
};

export default {
  name: 'Header',
  components: {
    TooltippedIcon,
    MenuSection,
    MenuItem
  },
  data() {
    return {
      viewMode: viewModes.full,
      isManageSectionExpanded: null,
      isClientsSectionExpanded: null,
      isConfigurationSectionExpanded: null,
      isMaintenanceSectionExpanded: null,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/getUserRole',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    isTextVisible() {
      return this.viewMode === viewModes.full;
    },
    logoLink() {
      if (!this.userRole) {
        return '/';
      }
      return this.isAccessable('jobs') ? '/jobs' : '/tsheets-mapping';
    },
    menuSections() {
      return menuSections;
    },
    supportPageUrl() {
      return config.supportPageUrl;
    }
  },
  updated() {
    const menuSection = this.$router?.currentRoute?.value?.meta?.menuSection;
    if (this.isManageSectionExpanded === null && menuSection) {
      this.isManageSectionExpanded = menuSection === this.menuSections.manage;
      this.isClientsSectionExpanded = menuSection === this.menuSections.clients;
      this.isConfigurationSectionExpanded = menuSection === this.menuSections.configuration;
      this.isMaintenanceSectionExpanded = menuSection === this.menuSections.maintenance;
    }
  },
  methods: {
    ...mapActions({
      authLogOut: 'auth/logOut'
    }),
    logOut() {
      this.authLogOut();
      this.$router.push('/auth');
    },
    isAccessable(path) {
      return validateRouteAccess(path, this.userRole);
    },
    isSectionAccessable(sectionName) {
      return validateSectionAccess(sectionName, this.userRole);
    },
    isActive(name) {
      return name === this.$router.currentRoute.value.name;
    },
    switchViewMode() {
      if (this.viewMode === viewModes.full) {
        this.viewMode = viewModes.short;
      } else {
        this.viewMode = viewModes.full;
      }
    }
  }
};
</script>

<style scoped>
.main-menu {
  height: 100vh;
  width: 220px;
}

.view-mode-short {
  width: 60px !important;
}

.view-mode-switcher {
  margin: 0;
}

.view-mode-short-switcher {
  margin-left: -10px;
}

.navbar-scrollable {
  overflow-y: auto;
}
</style>
