<template>
  <div
    id="location-timezone-modal"
    ref="locationTimeZoneModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Add mapping
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form>
            <TextInput
              v-model="facilityNumber"
              :errors="v$.facilityNumber.$errors"
              description="Location code"
              label="Location code"
              @blur="v$.facilityNumber.$touch"
            />
            <TextInput
              v-model="timeZone"
              :errors="v$.timeZone.$errors"
              description="Location timezone"
              label="Location timezone"
              @blur="v$.timeZone.$touch"
            />
          </form>
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="mappingSaveConfirmationModal"
    :onCancelHandler="cancelSaveHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The location - timezone mapping will be saved. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmationModal from '../common/ConfirmationModal';
import TextInput from '../common/TextInput';
import { Modal } from 'bootstrap';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    ConfirmationModal,
    TextInput,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmationModalSave: null,
      mappingSaveModal: null,
      facilityNumber: null,
      timeZone: null
    };
  },
  validations() {
    return {
      facilityNumber: { required },
      timeZone: { required }
    };
  },
  computed: {
    ...mapGetters({
      mappings: 'locationTimeZoneMapping/mappings',
    }),
    isSaveButtonDisabled() {
      return false;
    },
  },
  async mounted() {
    this.mappingSaveModal = new Modal(this.$refs['locationTimeZoneModal']);
    this.confirmationModalSave = this.$refs['mappingSaveConfirmationModal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchMappings: 'locationTimeZoneMapping/fetchAllMappings',
      saveMapping: 'locationTimeZoneMapping/saveMapping'
    }),
    initModal() {
      this.mappingSaveModal.show();
    },
    isDuplicateFacilityNumber() {
      return this.mappings.some((obj) => {
        if (obj.facilityNumber) {
          return obj.facilityNumber === this.facilityNumber;
        }
        return false;
      });
    },
    async validateForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return 'Form should be valid.';
      }
      if (this.isDuplicateFacilityNumber()) {
        return 'This location code already exists in the mapping list.';
      }
    },
    async showSaveConfirmationModalHandler() {
      const errorMessage = await this.validateForm();
      if (errorMessage) {
        this.$toast.error(errorMessage);
        return false;
      }
      this.mappingSaveModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        facilityNumber: this.facilityNumber,
        timeZone: this.timeZone
      };
      try {
        await this.saveMapping(dataToSave);
        this.$toast.success('Mapping successfully created');
        await this.fetchMappings();
      } catch (e) {
        this.$toast.error('Failed to save mapping.');
      }
      finally {
        this.resetMapping();
      }
    },
    cancelSaveHandler() {
      this.confirmationModalSave.hide();
    },
    resetMapping() {
      this.facilityNumber = null;
      this.timeZone = null;
    }
  }
};
</script>

