<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <div class="card m-auto mapping-container">
          <div class="card-header p-3 d-flex justify-content-between">
            <h5 class="mt-1 mb-0">
              Location Time Zone Mappings
            </h5>
            <button
              v-if="isAddMappingBtnVisible"
              class="btn btn-sm btn-primary"
              @click="openCreateMappingModal()"
            >
              Add
            </button>
          </div>
          <div
            v-if="mappings.length"
            class="card-body p-3 d-flex justify-content-center"
            style="height: 50em;"
          >
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="mappings"
              class="ag-theme-alpine h-100 w-100"
            />
          </div>
          <Spinner v-else />
        </div>
      </div>
    </div>
  </div>
  <LocationTimeZoneCreateModal
    ref="locationTimeZoneCreateModal"
  />
  <DeleteConfirmationModal
    ref="locationTimeZoneDeleteConfirmationModal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  >
    <template #alert-message>
      Do you really want to delete this mapping?
      This operation can not be undone!
    </template>
  </DeleteConfirmationModal>
</template>
<script>
import Spinner from '@/components/common/Spinner';
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import LocationTimeZoneCreateModal from '@/components/LocationTimeZoneMapping/LocationTimeZoneCreateModal.vue';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal.vue';
import { hasPrivilege } from '@/service/userAccess';
import { userPrivileges } from '@/components/constants';

export default {
  components: {
    DeleteConfirmationModal,
    LocationTimeZoneCreateModal,
    Spinner,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      confirmationModalDelete: null,
      mappingOnDelete: null
    };
  },
  computed: {
    ...mapGetters({
      mappings: 'locationTimeZoneMapping/mappings',
    }),
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Delete',
            buttonVariant: 'btn-danger',
            visibilityCheck() {
              return hasPrivilege(userPrivileges.deleteLocationTimeZoneMapping);
            },
            clicked: (id) => {
              this.openDeleteConfirmationModal(id);
            }
          },
          width: 100
        },
        {
          headerName: 'Location code',
          field: 'facilityNumber',
          width: 128,
          sortable: false,
        },
        {
          headerName: 'Timezone',
          field: 'timeZone',
          width: 250,
          sortable: false
        }
      ];
    },
    isAddMappingBtnVisible() {
      return hasPrivilege(userPrivileges.createLocationTimeZoneMapping);
    }
  },
  async mounted() {
    await this.fetchMappings();
    this.confirmationModalDelete = this.$refs['locationTimeZoneDeleteConfirmationModal'].confirmationModal;
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchMappings: 'locationTimeZoneMapping/fetchAllMappings',
      deleteMapping: 'locationTimeZoneMapping/deleteMapping',
    }),
    openDeleteConfirmationModal(id) {
      this.mappingOnDelete = id;
      this.confirmationModalDelete.show();
    },
    openCreateMappingModal() {
      this.$refs['locationTimeZoneCreateModal'].initModal();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteMapping(this.mappingOnDelete);
        await this.fetchMappings();
        this.$toast.success('Mapping successfully deleted');
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
      finally {
        this.mappingOnDelete = null;
      }
    }
  }
};
</script>
<style>
.mapping-container {
  max-width: 500px;
  max-height: 600px;
}
</style>

