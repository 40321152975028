<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col">
        <div class="card user-management-container">
          <div class="card-header d-flex justify-content-between">
            <button
              v-if="isAddBtnVisible"
              class="btn btn-sm btn-primary"
              @click="addNewClickHandler"
            >
              Add new
            </button>
            <h5 class=" mt-1 mb-0">
              User management
            </h5>
            <h5 class="mt-1 mb-0">
              <input
                id="filter-text-box"
                v-model="searchQuery"
                type="text"
                placeholder="Search email..."
              />
            </h5>
          </div>
          <div class="card-body w-100 p-0 ag-grid-container">
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="filteredItems"
              class="ag-theme-alpine h-100 w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <UserManagementModal
    ref="userManagementModal"
    :viewType="modalViewType"
  />
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapActions, mapGetters } from 'vuex';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { mappedUserRoles, modalViewTypes, userPrivileges, userStatuses } from '@/components/constants';
import UserManagementModal from '@/components/UserManagement/UserManagementModal';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';
import { hasPrivilege } from '@/service/userAccess';
import _ from 'lodash';

const cellClass = {
  [`${userStatuses.active}`]: 'text-primary',
  [`${userStatuses.disabled}`]: 'text-danger'
};

export default {
  components: {
    UserManagementModal,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      modalViewType: null,
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      rowData: 'userManagement/users',
      userRoles: 'userRole/userRoles',
      brands: 'brandManagement/processedBrandNames',
    }),
    filteredItems() {
      const queryTrim = this.searchQuery.trim();
      if (queryTrim.length < 1) {
        return this.mappedUsers;
      }
      const query = queryTrim.toLowerCase();
      return this.mappedUsers.filter((item) => {
        return item.email.toLowerCase().includes(query);
      });
    },
    isAddBtnVisible() {
      return hasPrivilege(userPrivileges.createUser);
    },
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.rowClickHandler(field);
            },
            visibilityCheck: () => {
              return hasPrivilege(userPrivileges.updateUser);
            }
          },
          width: 80
        },
        {
          headerName: 'ID',
          field: 'id',
          width: 80
        },
        {
          headerName: 'Full name',
          field: 'fullName',
          width: 150,
          sortable: true,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 250,
          sortable: true,
        },
        {
          headerName: 'Slack',
          field: 'slackId',
          width: 150
        },
        {
          headerName: 'Locations',
          field: 'locations',
          width: 150
        },
        {
          headerName: 'Role',
          field: 'role',
          width: 150,
          sortable: true,
          cellRenderer: (params) => {
            const userRole = this.userRoles.find((item) => item.id === params.data.role)?.name;
            return mappedUserRoles[userRole].role || null;
          }
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 100,
          cellRenderer: (params) => {
            return `<span class="${cellClass[params.value] || ''}">${params.value}</span>`;
          }
        },
        {
          headerName: 'MFA Configured',
          field: 'usingMFA',
          cellRenderer: booleanIconCellRenderer(),
          width: 75
        }
      ];
    },
    mappedUsers() {
      return this.rowData.map((user) => {
        const brands = _.map(user.brands, (brandId) => {
          const brand = _.find(this.brands, { id: brandId });
          if (brand) {
            return brand.lookupName;
          }
          return null;
        });
        return _.merge({}, user, { brands });
      });
    }
  },
  async mounted() {
    await Promise.all([
      await this.fetchUsers(),
      await this.fetchProcessedBrandNames()
    ]);
  },
  methods: {
    ...mapActions({
      fetchUsers: 'userManagement/fetchUsers',
      fetchProcessedBrandNames: 'brandManagement/fetchProcessedBrandNames',
    }),
    async rowClickHandler(itemId) {
      this.modalViewType = modalViewTypes.edit;
      await this.$refs.userManagementModal.initModal(itemId);
    },
    async addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      await this.$refs.userManagementModal.initModal();
    }
  }
};
</script>
<style scoped>
.ag-grid-container {
  height: 40em;
}

.user-management-container {
  width: 1190px;
  margin: 0 auto;
}
</style>
