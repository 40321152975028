<template>
  <div
    id="forecast-modal"
    ref="forecastModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.userName"
              description="User name"
              label="User name"
              :readonly="true"
            />
            <TextInput
              v-model="item.email"
              description="User email"
              label="User email"
              :readonly="true"
            />
            <CheckBoxSelectorWithSelectAllHook
              v-model="item.userForecastAvailableBrands"
              v-model:selectAll="item.selectAllForecastAvailableBrands"
              :options="brandOptions"
              description="Processed brands"
              label="Processed brands"
            />
            <CheckboxInput
              v-model="item.receiveForecastDecision"
              description="You can disable notification about forecast decision for the user."
              label="Receive forecast decisions"
            />
            <CheckboxInput
              v-model="item.receiveLackOfForecastAlert"
              description="You can disable lack of forecast alerts for the user."
              label="Receive lack of forecast alerts"
            />
            <CheckboxInput
              v-model="item.receiveNewForecastAlert"
              description="You can disable new forecast alerts for the user."
              label="Receive new forecast alerts"
            />
            <CheckboxInput
              v-model="item.receiveMismatchAlert"
              description="You can disable mismatch forecast alerts for the user."
              label="Receive mismatch forecast alerts"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-end">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="forecast-user-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The forecast user will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Modal } from 'bootstrap';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import { modalViewTypes } from '@/components/constants';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import CheckboxInput from '@/components/common/CheckboxInput';
import CheckBoxSelectorWithSelectAllHook from '@/components/common/CheckBoxSelectorWithSelectAllHook.vue';

export default {
  components: {
    CheckBoxSelectorWithSelectAllHook,
    ConfirmationModal,
    TextInput,
    Spinner,
    CheckboxInput
  },
  props: ['viewType'],
  data() {
    return {
      confirmationModalSave: null,
      forecastUserEditModal: null,
      initialized: false,
      item: {
        userId: null,
        userName: null,
        email: null,
        userForecastAvailableBrands: null,
        selectAllForecastAvailableBrands: null,
        userAvailableBrands: null,
        receiveForecastDecision: false,
        receiveMismatchAlert: false,
        receiveLackOfForecastAlert: false,
        receiveNewForecastAlert: false
      }
    };
  },
  computed: {
    ...mapGetters({
      actualBrands: 'forecast/companyItems',
    }),
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit user' : 'Add New';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    brandOptions() {
      return Object.values(this.actualBrands)
        .filter((item) => this.item.userAvailableBrands.includes(item.brandId))
        .map((item) => ({ key: item.brandId, value: item.name }));
    }
  },
  async mounted() {
    this.forecastUserEditModal = new Modal(this.$refs['forecastModal']);
    this.confirmationModalSave = this.$refs['forecast-user-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchForecastUserById: 'forecast/fetchForecastUserById',
      updateForecastUser: 'forecast/updateForecastUser',
      fetchForecastUsers: 'forecast/fetchForecastUsers'
    }),
    async initModal(itemId) {
      this.initialized = false;
      this.forecastUserEditModal.show();
      const itemToEdit = await this.fetchForecastUserById(itemId);
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.userId = newValue.userId;
      this.item.userName = newValue.userName;
      this.item.email = newValue.email;
      this.item.userForecastAvailableBrands = newValue.userForecastAvailableBrands;
      this.item.selectAllForecastAvailableBrands = newValue.selectAllForecastAvailableBrands;
      this.item.userAvailableBrands = newValue.userAvailableBrands;
      this.item.receiveForecastDecision = newValue.receiveForecastDecision;
      this.item.receiveMismatchAlert = newValue.receiveMismatchAlert;
      this.item.receiveLackOfForecastAlert = newValue.receiveLackOfForecastAlert;
      this.item.receiveNewForecastAlert = newValue.receiveNewForecastAlert;
    },
    async showSaveConfirmationModalHandler() {
      this.forecastUserEditModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.item
      };
      try {
        await this.updateForecastUser(dataToSave);
        const message = 'User successfully updated';
        this.$toast.success(message);
        await this.fetchForecastUsers();
      } catch (e) {
        this.$toast.error('Failed to save the user.<br/>'+ e);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
  }
};
</script>
<style scoped></style>
