export default {
  namespaced: true,
  state: () => ({
    mappings: [],
  }),
  getters: {
    mappings: (state) => state.mappings,
  },
  actions: {
    fetchAllMappings: async ({ dispatch, commit }) => {
      const params = {
        path: 'location-timezone-mapping',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_MAPPINGS', await response.json());
    },
    saveMapping: async ({ dispatch }, data) => {
      const params = {
        path: 'location-timezone-mapping',
        method: 'POST',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    deleteMapping: async ({ dispatch }, id) => {
      const params = {
        path: `location-timezone-mapping/${id}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    }
  },
  mutations: {
    'SET_MAPPINGS': (state, items) => {
      state.mappings = items;
    }
  }
};
