export const modalViewTypes = {
  edit: 'edit',
  add: 'add'
};

export const addLeadZero = (t) => {
  return t < 10 ? `0${t}` : `${t}`;
};

export const dateFormatter = (date) => {
  if (typeof date === 'string') {
    return date;
  }
  const day = addLeadZero(date.getDate());
  const month = addLeadZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const userRoles = {
  admin: 'ROLE_ADMIN',
  opsTeam: 'ROLE_MANAGER',
  superAdmin: 'ROLE_SUPER_ADMIN',
  accountTeam: 'ROLE_FINANCE_MANAGER',
  viewer: 'ROLE_VIEWER',
  manager: 'ROLE_LP_MANAGER',
  planner: 'ROLE_LP_PLANNER'
};

export const mappedUserRoles = {
  'ROLE_ADMIN': {
    role: 'Admin',
    description: 'Has all privileges except those of the Super Admin. Excludes system-wide configuration and User Management.'
  },
  'ROLE_MANAGER': {
    role: 'Manager',
    description: 'Can read, update, and upload data in Brand Management and Forecasts features.'
  },
  'ROLE_SUPER_ADMIN': {
    role: 'Super Admin',
    description: 'Has unrestricted access to all system features and administrative functions.'
  },
  'ROLE_FINANCE_MANAGER': {
    role: 'Finance manager',
    description: 'Can read, update, and upload data in Brand Management, Invoices, and Forecasts features.'
  },
  'ROLE_VIEWER': {
    role: 'Viewer',
    description: 'Has read-only access to Brand Management and Forecasts features.'
  },
  'ROLE_LP_MANAGER': {
    role: 'LP manager',
    description: 'Can read, approve or reject, and allow resubmissions in the Labor Planning feature.'
  },
  'ROLE_LP_PLANNER': {
    role: 'LP planner',
    description: 'Can read, create, and update data in the Labor Planning feature.'
  }
};

export const userPrivileges = {
  getBrandsOverviews: 'GET_BRANDS_OVERVIEWS',
  getBrandsActualNames: 'GET_BRANDS_ACTUAL_NAMES',
  createCustomerPortalUser: 'CREATE_CUSTOMER_PORTAL_USER',
  createAgency: 'CREATE_AGENCY',
  getAgency: 'GET_AGENCY',
  updateAgency: 'UPDATE_AGENCY',
  deleteAgency: 'DELETE_AGENCY',
  getProcessedBrandsNames: 'GET_PROCESSED_BRANDS_NAMES',
  getBrandExclusions: 'GET_BRAND_EXCLUSIONS',
  createBrandExclusion: 'CREATE_BRAND_EXCLUSION',
  updateBrandExclusion: 'UPDATE_BRAND_EXCLUSION',
  deleteBrandExclusion: 'DELETE_BRAND_EXCLUSION',
  getBrandDetails: 'GET_BRAND_DETAILS',
  getBrandUph: 'GET_BRAND_UPH',
  updateBrandUph: 'UPDATE_BRAND_UPH',
  createBrandUph: 'CREATE_BRAND_UPH',
  uploadBrandUph: 'UPLOAD_UPH_RULE',
  getUphRule: 'GET_UPH_RULE',
  getCustomerPortalUser: 'GET_CUSTOMER_PORTAL_USER',
  updateCustomerPortalUser: 'UPDATE_CUSTOMER_PORTAL_USER',
  deleteCustomerPortalUser: 'DELETE_CUSTOMER_PORTAL_USER',
  createDashboard: 'CREATE_DASHBOARD',
  getDashboard: 'GET_DASHBOARD',
  updateDashboard: 'UPDATE_DASHBOARD',
  deleteDashboard: 'DELETE_DASHBOARD',
  getLpPlan: 'GET_LP_PLAN',
  getLpAvailablePlans: 'GET_LP_AVAILABLE_BRANDS',
  submitLpPlan: 'SUBMIT_LP_PLAN',
  updateLpPlan: 'UPDATE_LP_PLAN',
  getLpRule: 'GET_LP_RULE',
  updateLpRule: 'UPDATE_LP_RULE',
  getLpManagers: 'GET_LP_MANAGERS',
  getLpPlanners: 'GET_LP_PLANNERS',
  getLpAvailableManagers: 'GET_LP_AVAILABLE_MANAGERS',
  getLpAvailablePlanners: 'GET_LP_AVAILABLE_PLANNERS',
  getLpParticipant: 'GET_LP_PARTICIPANT',
  updateLpParticipant: 'UPDATE_LP_PARTICIPANT',
  updateLpPlanStatus: 'UPDATE_LP_PLAN_STATUS',
  allowResubmitLpPlan: 'ALLOW_RESUBMIT_LP_PLAN',
  getLpActualWeeklyUnits: 'GET_LP_ACTUAL_WEEKLY_UNITS',
  getOsServices: 'GET_OS_SERVICES',
  createUser: 'CREATE_USER',
  getUser: 'GET_USER',
  updateUser: 'UPDATE_USER',
  deactivateUser: 'DEACTIVATE_USER',
  resetUserPassword: 'RESET_USER_PASSWORD',
  activateUser: 'ACTIVATE_USER',
  getRoles: 'GET_ROLES',
  getPrivileges: 'GET_PRIVILEGES',
  getBrandForecast: 'GET_BRAND_FORECAST',
  getForecastDetailsHistory: 'GET_FORECAST_DETAIL_HISTORY',
  getLastUploadForecasts: 'GET_LAST_UPLOAD_FORECASTS',
  updateForecastStatus: 'UPDATE_FORECAST_STATUS',
  getForecastRecipient: 'GET_FORECAST_RECIPIENT',
  createForecastRecipient: 'CREATE_FORECAST_RECIPIENT',
  deleteForecastRecipient: 'DELETE_FORECAST_RECIPIENT',
  uploadAdminForecast: 'UPLOAD_ADMIN_FORECAST',
  getForecastByBrand: 'GET_FORECAST_BY_BRAND',
  getForecastRule: 'GET_FORECAST_RULE',
  updateForecastRule: 'UPDATE_FORECAST_RULE',
  createForecastRule: 'CREATE_FORECAST_RULE',
  getForecastActualBrands: 'GET_FORECAST_ACTUAL_BRANDS',
  getAdminForecastTemplate: 'GET_ADMIN_FORECAST_TEMPLATE',
  uploadForecastRule: 'UPLOAD_FORECAST_RULE_FILE',
  getForecastRuleFile: 'GET_FORECAST_RULE_FILE',
  updateForecastAlertToClientsStatus: 'UPDATE_FORECAST_ALERT_TO_CLIENTS_STATUS',
  getForecastAlertToClientsStatus: 'GET_FORECAST_ALERT_TO_CLIENTS_STATUS',
  getLastActualForecasts: 'GET_LAST_ACTUAL_FORECASTS',
  uploadShipheroReport: 'UPLOAD_SHIPHERO_REPORT',
  getSlaRule: 'GET_SLA_RULE',
  createSlaRule: 'CREATE_SLA_RULE',
  updateSlaRule: 'UPDATE_SLA_RULE',
  uploadSlaRuleFile: 'UPLOAD_SLA_RULE_FILE',
  getSlaRuleFile: 'GET_SLA_RULE_FILE',
  getUkgMapping: 'GET_UKG_MAPPING',
  createUkgMapping: 'CREATE_UKG_MAPPING',
  updateUkgMapping: 'UPDATE_UKG_MAPPING',
  deleteUkgMapping: 'DELETE_UKG_MAPPING',
  uploadUkgMapping: 'UPLOAD_UKG_MAPPINGS_FILE',
  getUkgMappingsFile: 'GET_UKG_MAPPINGS_FILE',
  getCompanyTypeMapping: 'GET_COMPANY_TYPE_MAPPING',
  createCompanyTypeMapping: 'CREATE_COMPANY_TYPE_MAPPING',
  updateCompanyTypeMappings: 'UPDATE_COMPANY_TYPE_MAPPINGS',
  deleteCompanyTypeMappings: 'DELETE_COMPANY_TYPE_MAPPINGS',
  createTableauDashboard: 'CREATE_TABLEAU_DASHBOARD',
  getTableauDashboardByName: 'GET_TABLEAU_DASHBOARD_BY_NAME',
  getTableauView: 'GET_TABLEAU_VIEW',
  getTableauDashboardTemplates: 'GET_TABLEAU_DASHBOARD_TEMPLATES',
  createTableauUser: 'CREATE_TABLEAU_USER',
  deleteTableauUser: 'DELETE_TABLEAU_USER',
  updateTableauClientGroup: 'UPDATE_TABLEAU_CLIENT_GROUP',
  updateTableauUserGroup: 'UPDATE_TABLEAU_USER_GROUP',
  getTsheetsMappings: 'GET_TSHEETS_MAPPINGS',
  createTsheetsMappings: 'CREATE_TSHEETS_MAPPINGS',
  updateTsheetsMappings: 'UPDATE_TSHEETS_MAPPINGS',
  deleteTsheetsMappings: 'DELETE_TSHEETS_MAPPINGS',
  uploadTsheetsMappings: 'UPLOAD_TSHEETS_MAPPINGS',
  uploadCompanyTypeMappings: 'UPLOAD_COMPANY_TYPE_MAPPINGS',
  uploadInvoices: 'UPLOAD_INVOICE',
  getLastUploadedInvoices: 'GET_LAST_UPLOADED_INVOICES',
  getTsheetsMappingFile: 'GET_TSHEETS_MAPPINGS_FILE',
  getCompanyTypeMappingsFile: 'GET_COMPANY_TYPE_MAPPINGS_FILE',
  getForecastFile: 'GET_FORECAST_FILE',
  getBrandLocation: 'GET_BRAND_LOCATION',
  updateBrandLocation: 'UPDATE_BRAND_LOCATION',
  createSlaAlertRecipient: 'CREATE_SLA_ALERT_RECIPIENT',
  getSlaAlertRecipient: 'GET_SLA_ALERT_RECIPIENT',
  deleteSlaAlertRecipient: 'DELETE_SLA_ALERT_RECIPIENT',
  getLocationTimeZoneMapping: 'GET_LOCATION_TIMEZONE_MAPPING',
  createLocationTimeZoneMapping: 'CREATE_LOCATION_TIMEZONE_MAPPING',
  deleteLocationTimeZoneMapping: 'DELETE_LOCATION_TIMEZONE_MAPPING',
  applyAllDatesInUploadForecast: 'APPLY_ALL_DATES_IN_UPLOADED_FORECAST',
};

export const mappedUserPrivileges = {
  'GET_LOCATION_TIMEZONE_MAPPING': 'Get location-timezone mapping',
  'CREATE_LOCATION_TIMEZONE_MAPPING': 'Create location-timezone mapping',
  'DELETE_LOCATION_TIMEZONE_MAPPING': 'Delete location-timezone mapping',
  'CREATE_SLA_ALERT_RECIPIENT': 'Create sla alert recipient',
  'GET_SLA_ALERT_RECIPIENT': 'Get sla alert recipient',
  'DELETE_SLA_ALERT_RECIPIENT': 'Delete sla alert recipient',
  'GET_BRANDS_OVERVIEWS': 'Get brands overviews',
  'GET_BRANDS_ACTUAL_NAMES': 'Get brands actual names',
  'CREATE_CUSTOMER_PORTAL_USER': 'Create customer portal user',
  'CREATE_AGENCY': 'Create agency',
  'GET_AGENCY': 'Get agency',
  'UPDATE_AGENCY': 'Update agency',
  'DELETE_AGENCY': 'Delete agency',
  'GET_PROCESSED_BRANDS_NAMES': 'Get processed brands names',
  'GET_BRAND_EXCLUSIONS': 'Get brand exclusions',
  'CREATE_BRAND_EXCLUSION': 'Create brand exclusion',
  'UPDATE_BRAND_EXCLUSION': 'Update brand exclusion',
  'DELETE_BRAND_EXCLUSION': 'Delete brand exclusion',
  'GET_BRAND_DETAILS': 'Get brand details',
  'GET_BRAND_UPH': 'Get brand UPH',
  'UPDATE_BRAND_UPH': 'Update brand UPH',
  'CREATE_BRAND_UPH': 'Create brand UPH',
  'UPLOAD_UPH_RULE': 'Upload UPH rule',
  'GET_UPH_RULE': 'Get UPH rule',
  'GET_CUSTOMER_PORTAL_USER': 'Get customer portal user',
  'UPDATE_CUSTOMER_PORTAL_USER': 'Update customer portal user',
  'DELETE_CUSTOMER_PORTAL_USER': 'Delete customer portal user',
  'CREATE_DASHBOARD': 'Create dashboard',
  'GET_DASHBOARD': 'Get dashboard',
  'UPDATE_DASHBOARD': 'Update dashboard',
  'DELETE_DASHBOARD': 'Delete dashboard',
  'GET_LP_PLAN': 'Get LP plan',
  'GET_LP_AVAILABLE_BRANDS': 'Get LP available brands',
  'SUBMIT_LP_PLAN': 'Submit LP plan',
  'UPDATE_LP_PLAN': 'Update LP plan',
  'GET_LP_RULE': 'Get LP rule',
  'UPDATE_LP_RULE': 'Update LP rule',
  'GET_LP_MANAGERS': 'Get LP managers',
  'GET_LP_PLANNERS': 'Get LP planners',
  'GET_LP_AVAILABLE_MANAGERS': 'Get LP available managers',
  'GET_LP_AVAILABLE_PLANNERS': 'Get LP available planners',
  'GET_LP_PARTICIPANT': 'Get LP participant',
  'UPDATE_LP_PARTICIPANT': 'Update LP participant',
  'UPDATE_LP_PLAN_STATUS': 'Update LP plan status',
  'ALLOW_RESUBMIT_LP_PLAN': 'Allow to resubmit LP plan',
  'GET_LP_ACTUAL_WEEKLY_UNITS': 'Get LP actual weekly units',
  'GET_OS_SERVICES': 'Get OS services',
  'CREATE_USER': 'Create user',
  'GET_USER': 'Get user',
  'UPDATE_USER': 'Update user',
  'DEACTIVATE_USER': 'Deactivate user',
  'RESET_USER_PASSWORD': 'Reset user password',
  'ACTIVATE_USER': 'Activate user',
  'GET_ROLES': 'Get roles',
  'GET_PRIVILEGES': 'Get privileges',
  'GET_BRAND_FORECAST': 'Get brand forecast',
  'GET_FORECAST_DETAIL_HISTORY': 'Get forecast detail history',
  'GET_LAST_UPLOAD_FORECASTS': 'Get last upload forecasts',
  'UPDATE_FORECAST_STATUS': 'Update forecast status',
  'GET_FORECAST_RECIPIENT': 'Get forecast recipient',
  'CREATE_FORECAST_RECIPIENT': 'Create forecast recipient',
  'DELETE_FORECAST_RECIPIENT': 'Delete forecast recipient',
  'UPLOAD_ADMIN_FORECAST': 'Upload admin forecast',
  'GET_FORECAST_BY_BRAND': 'Get forecast by brand',
  'GET_FORECAST_RULE': 'Get forecast rule',
  'UPDATE_FORECAST_RULE': 'Update forecast rule',
  'CREATE_FORECAST_RULE': 'Create forecast rule',
  'GET_FORECAST_ACTUAL_BRANDS': 'Get forecast actual brands',
  'GET_ADMIN_FORECAST_TEMPLATE': 'Get admin forecast template',
  'UPLOAD_FORECAST_RULE_FILE': 'Upload forecast rule file',
  'GET_FORECAST_RULE_FILE': 'Get forecast rule file',
  'UPDATE_FORECAST_ALERT_TO_CLIENTS_STATUS': 'Update forecast alert to clients status',
  'GET_FORECAST_ALERT_TO_CLIENTS_STATUS': 'Get forecast alert to clients status',
  'GET_LAST_ACTUAL_FORECASTS': 'Get last actual forecasts',
  'UPLOAD_SHIPHERO_REPORT': 'Upload ShipHero report',
  'GET_SLA_RULE': 'Get SLA rule',
  'CREATE_SLA_RULE': 'Create SLA rule',
  'UPDATE_SLA_RULE': 'Update SLA rule',
  'UPLOAD_SLA_RULE_FILE': 'Upload SLA rule file',
  'GET_SLA_RULE_FILE': 'Get SLA rule file',
  'GET_UKG_MAPPING': 'Get UKG mapping',
  'CREATE_UKG_MAPPING': 'Create UKG mapping',
  'UPDATE_UKG_MAPPING': 'Update UKG mapping',
  'DELETE_UKG_MAPPING': 'Delete UKG mapping',
  'UPLOAD_UKG_MAPPINGS_FILE': 'Upload UKG mappings file',
  'GET_UKG_MAPPINGS_FILE': 'Get UKG mappings file',
  'GET_COMPANY_TYPE_MAPPING': 'Get company type mapping',
  'CREATE_COMPANY_TYPE_MAPPING': 'Create company type mapping',
  'UPDATE_COMPANY_TYPE_MAPPINGS': 'Update company type mappings',
  'DELETE_COMPANY_TYPE_MAPPINGS': 'Delete company type mappings',
  'CREATE_TABLEAU_DASHBOARD': 'Create Tableau dashboard',
  'GET_TABLEAU_DASHBOARD_BY_NAME': 'Get Tableau dashboard by name',
  'GET_TABLEAU_VIEW': 'Get Tableau view',
  'GET_TABLEAU_DASHBOARD_TEMPLATES': 'Get Tableau dashboard templates',
  'CREATE_TABLEAU_USER': 'Create Tableau user',
  'DELETE_TABLEAU_USER': 'Delete Tableau user',
  'UPDATE_TABLEAU_CLIENT_GROUP': 'Update Tableau client group',
  'UPDATE_TABLEAU_USER_GROUP': 'Update Tableau user group',
  'GET_TSHEETS_MAPPINGS': 'Get TSheets mappings',
  'CREATE_TSHEETS_MAPPINGS': 'Create TSheets mappings',
  'UPDATE_TSHEETS_MAPPINGS': 'Update TSheets mappings',
  'DELETE_TSHEETS_MAPPINGS': 'Delete TSheets mappings',
  'UPLOAD_TSHEETS_MAPPINGS': 'Upload TSheets mappings',
  'UPLOAD_COMPANY_TYPE_MAPPINGS': 'Upload company type mappings',
  'UPLOAD_INVOICE': 'Upload invoice',
  'GET_LAST_UPLOADED_INVOICES': 'Get last uploaded invoices',
  'GET_TSHEETS_MAPPINGS_FILE': 'Get TSheets mappings file',
  'GET_COMPANY_TYPE_MAPPINGS_FILE': 'Get company type mappings file',
  'GET_FORECAST_FILE': 'Download forecast file',
  'GET_JOB_LOG': 'Get job logs',
  'GET_BRAND_LOCATION': 'Get brand locations',
  'UPDATE_BRAND_LOCATION': 'Update brand locations',
  'APPLY_ALL_DATES_IN_UPLOADED_FORECAST': 'Apply all dates in uploaded forecast',
};

export const roleLandingPage = {
  [userRoles.admin]: '/jobs',
  [userRoles.opsTeam]: '/forecasts',
  [userRoles.superAdmin]: '/jobs',
  [userRoles.accountTeam]: '/invoices-upload',
  [userRoles.viewer]: '/forecasts',
  [userRoles.planner]: '/labor-planning',
  [userRoles.manager]: '/labor-planning'
};

export const databaseTypes = [ 'PROD', 'DEV' ];

export const menuSections = {
  manage: 'manage',
  configuration: 'configuration',
  maintenance: 'maintenance',
  clients: 'clients'
};

export const settingNames = {
  companyType: 'Company Type mapping',
  ukgMapping: 'UKG mapping',
  uphThresholds: 'UPH thresholds',
  slaRules: 'SLA rules',
  forecastThresholds: 'Forecast thresholds'
};

export const downloadUrls = {
  [settingNames.companyType]: '/uploads/mapping/download/company-type',
  [settingNames.ukgMapping]: '/ukg-mapping/download',
  [settingNames.uphThresholds]: '/brand/uph/download',
  [settingNames.slaRules]: '/sla-configuration/download/sla-rules',
  [settingNames.forecastThresholds]: '/forecast/download/thresholds'
};

export const tableauDashboardBaseUrl = 'https://prod-useast-b.online.tableau.com/t/outerspace/views/';

export const userStatuses = {
  active: 'ACTIVE',
  disabled: 'DISABLED'
};
