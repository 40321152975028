<template>
  <CheckBoxSelectorWithInputs
    v-bind="$attrs"
    v-bind:modelValue="modelValue"
    v-bind:options="options"
    v-on="$attrs"
    @update:modelValue="updateModelValue"
  />
</template>

<script>
import CheckBoxSelectorWithInputs from './CheckBoxSelectorWithInputs.vue';

export default {
  components: {
    CheckBoxSelectorWithInputs
  },
  props: [
    'modelValue',
    'options'
  ],
  emits: [
    'update:modelValue',
    'update:selectAll'
  ],
  methods: {
    updateModelValue(newValue) {
      this.$emit('update:modelValue', newValue);
      const isAllSelected = newValue.length === this.options.length;
      this.$emit('update:selectAll', isAllSelected);
    },
  },
};
</script>
